import React from "react";
import "./style.css";
import {Header} from "./Header";
import HowToPlay from "./HowToPlay";
import Disclaimer from "./Disclaimer";
import Tokenomics from "./Tokenomics";

export const Homepage = () => {
    return (
        <div className="homepage">
            <div className="div">
                <Header />
                <HowToPlay />
                <Tokenomics />
                <Disclaimer />
            </div>
        </div>
    );
};
