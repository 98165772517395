import React from 'react';

function HowToPlay() {
    return (
        <div className="how-to-play">
            <div className="title-text">How to play:</div>
            <img className="parplane" alt="Parplane" src="/images/parplane-2.png" />

            <div className="instructions">

                <div className="how-to-play-headers">1. Download Metamask</div>
                <div className="how-to-play-text">
                    Download and install metamask on the appstore, or, alternatively if you're on desktop download and
                    install the browser extension.
                </div>
                <br />
                <div className="how-to-play-headers">2. Acquire ETH</div>
                <div className="how-to-play-text">
                    Now all you've got to do is buy your ETH in the METAMASK APP, or, alternatively you can use an
                    exchange and deposit using your wallet address.
                </div>
                <br />

                <div className="how-to-play-headers">3. Buy some $WG</div>
                <div className="how-to-play-text"> Now go to Uniswap and paste the $WG </div>
                <div className="how-to-play-text"> Contract Address to swap your ETH. </div>
                <div className="how-to-play-text"> Remember, keep some for gas! </div>

                <br />
                <div className="how-to-play-headers">4. War Games</div>

                <div className="how-to-play-text">
                    [Redacted].
                </div>
                <br />
            </div>

            <img className="warpepe-1" alt="Warpepe" src="/images/pepeSoldierLeftFacing.png"/>
            <img className="warpepe-2" alt="Warpepe" src="/images/pepeSoldierLeftFacing.png"/>
            <img className="warpepe-3" alt="Warpepe" src="/images/pepeSoldierLeftFacing.png"/>
            <img className="warpepe-4" alt="Warpepe" src="/images/pepeSoldierLeftFacing.png"/>
            <img className="warpepe-5" alt="Warpepe" src="/images/pepeSoldierLeftFacing.png"/>
            <img className="pepe-angry" alt="Pepe angry" src="/images/pepe-angry.png" />

        </div>


    );
}

export default HowToPlay;