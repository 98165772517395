import React from 'react';

export function Disclaimer() {
    return (
        <div className="disclaimer">
            <img className="parplane-1" alt="Parplane" src="/images/parplane-1.png" />
            <img className="parplane-2" alt="Parplane" src="/images/parplane-1.png" />
            <img className="parplane-3" alt="Parplane" src="/images/parplane-1.png" />
            <div className="title-text">Disclaimer</div>
            <div className="wg-is-a-memecoin">
                <div className="disclaimer-text">
                    <br />
                    $wG is a memecoin that has no value or expectation of financial return. There is no formal roadmap and no
                    formal team, this coin is just for entertainment purposes. The token and games are built agnostic in
                    respect to any real world sides in current wars
                    <br />
                </div>
                <div className="disclaimer-hashtag">#choosePeace</div>
                <img className="pepelazergun" alt="Pepelazergun" src="/images/pepelazergun.png" />
            </div>
        </div>
    );
}

export default Disclaimer;