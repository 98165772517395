import React from 'react';


export function Tokenomics() {
    return (
        <div className="tokenomics">
            <div className="title-text">Tokenomics</div>


            <div className="tax">5% TAX: SET SLIPPAGE TO 5+%</div>

            <div className="no-taxes-on">NO TAXES ON TRANSFERS & STAKING (COMING SOON) </div>

            <div className="tokenomics-details">
              <div>○ 2% TO MARKETING</div>
              <div>○ 1% TO LIQUIDITY POOL</div>
              <div>○ 2% TO WAR CHEST/REWARDS</div>
              {/*<br/>*/}
              {/*/!*<div> 1 BILLION MAX SUPPLY</div>*!/*/}
              {/*<br/>*/}

            </div>


        </div>
    );
}

export default Tokenomics;